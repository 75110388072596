<template>
    <div class="module">
        <van-nav-bar title="消防联动" left-arrow  @click-left="$router.go(-1)" />
        <van-steps direction="vertical" :active="14" active-color="#1677ff" class="setps-content">
            <van-step v-for="(item,index) in devList" :key="index">
                <div class="title">
                    {{item.name}}
                </div>
                <div v-if="item.type === 1">
                    <div class="marbtm5">
                        控制状态：
                        <van-tag size="medium" type="primary" v-if="item.status0">自动</van-tag>
                        <van-tag size="medium" type="warning" v-else>手动</van-tag>
                    </div>
                    <div class="marbtm5">当前液位：{{item.current }} </div>
                    <div class="marbtm5">液位范围：{{`${item.max}~${item.max}`}} </div>
                    <div class="marbtm5">故障告警：{{item.status1 ? '正常' : '故障' }}</div>
                    <div class="marbtm5">水泵启停状态：{{item.status2 ? '正常' : '故障' }}</div>
                    <div class="marbtm5">接合器运行状态：{{item.status3 ? '正常' : '故障' }}</div>
                    <div class="marbtm5">接合器故障报警：{{item.status4 ? '暂无' : '' }}</div>
                </div>
                <div v-else-if="item.type === 2">
                    <div className="marbtm5">消防水管水压值：{{item.val }} kPa</div>
                </div>
                <div v-else-if="item.type === 3">
                    <div>设备运行情况：
                        <van-tag size="medium" type="primary" v-if="item.status">监测中</van-tag>
                        <van-tag size="medium" type="warning" v-else>故障</van-tag>
                    </div>
                </div>
                <div v-else-if="item.type === 4">
                    <div className="marbtm5">控制状态：
                        <van-tag size="medium" type="primary" v-if="item.status0">自动</van-tag>
                        <van-tag size="medium" type="warning" v-else>手动</van-tag>
                    </div>
                    <div className="marbtm5">运行状态：{{item.status1 ? '正常' : '故障'}}</div>
                    <div className="marbtm5">火灾报警：{{item.status2 ? '暂无' : '故障'}}</div>
                    <div className="marbtm5">故障报警：{{item.status3 ? '暂无' : '正常'}}</div>
                    <div className="marbtm5">报警设备信息：{{item.status4 ? '暂无' : '' }}</div>
                </div>
                <div>更新时间：{{item.time}}</div>
            </van-step>
        </van-steps>
    </div>
</template>

<script>
export default {
    data(){
        return{
            devList:[
                {name:'水泵',type:1,status0:1,status1:1,status2:1,status3:1,status4:1,current:'240.65',max:'280',min:'200',time:'2021-11-01 12:30'},
                {name:'压力传感器-ACU3-01',type:2,status:1,val:'0.00',time:'2021-11-01 08:30'},
                {name:'压力传感器-ACU3-02',type:2,status:1,val:'0.00',time:'2021-11-01 12:30'},
                {name:'双波长火焰探测器-进京01',type:3,status:1,time:'2021-11-01 02:20'},
                {name:'双波长火焰探测器-进京02',type:3,status:1,time:'2021-11-01 11:30'},
                {name:'双波长火焰探测器-出京04',type:3,status:1,time:'2021-11-01 12:30'},
                {name:'双波长火焰探测器-出京05',type:3,status:1,time:'2021-11-02 12:00'},
                {name:'火灾手报按钮-JFIR1-1',type:4,status0:1,status1:1,status2:1,status3:0,status4:1,time:'2021-11-02 12:10'},
                {name:'火灾手报按钮-JFIR1-2',type:4,status0:1,status1:1,status2:1,status3:0,status4:1,time:'2021-11-02 14:30'},
                {name:'火灾手报按钮-JFIR1-3',type:4,status0:1,status1:1,status2:1,status3:0,status4:1,time:'2021-11-02 14:50'},
                {name:'火灾手报按钮-JFIR1-4',type:4,status0:1,status1:1,status2:1,status3:0,status4:1,time:'2021-11-02 15:30'},
                {name:'火灾手报按钮--CFIR1-1',type:4,status0:1,status1:1,status2:1,status3:0,status4:1,time:'2021-11-02 16:00'},
                {name:'火灾手报按钮--CFIR1-2',type:4,status0:1,status1:1,status2:1,status3:0,status4:1,time:'2021-11-02 18:10'},
                {name:'火灾手报按钮--CFIR1-3',type:4,status0:1,status1:1,status2:1,status3:0,status4:1,time:'2021-11-02 19:00'},
                {name:'火灾手报按钮--CFIR1-4',type:4,status0:1,status1:1,status2:1,status3:0,status4:1,time:'2021-11-02 19:10'},
            ]
        }
    },
    methods:{
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/tunnel/module.scss';
</style>